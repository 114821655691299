// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require("jquery").start()
 
var $ = require( "jquery" );
// require("@rails/ujs").start()
import {} from 'jquery-ujs'
require("@rails/activestorage").start()
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// I18n
//require("jquery")
require("channels")
import I18n from 'i18n-js'
window.I18n = I18n
require("packs/customs/app_common")
require('apexcharts')

import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

import Tribute from "tributejs";
window.Tribute = Tribute
require("universal-cookie")
import Cookies from 'universal-cookie';
const cookies = new Cookies();
window.s_cookies = cookies;
